"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stateKeysExists = stateKeysExists;
exports.stateKeyExists = stateKeyExists;
exports.setNestedValue = setNestedValue;
exports.getNestedValue = getNestedValue;

function stateKeysExists(state, keys, type) {
  keys.forEach(function (key) {
    return stateKeyExists(state, key, type);
  });
}

function stateKeyExists(state, key, type) {
  if (typeof getNestedValue(state, key) === 'undefined') {
    console.error("Invalid state key : ".concat(key, " in ").concat(type));
  }
}

function setNestedValue(state, dotKey, value) {
  dotKey.split('.').reduce(function (acc, key, index, arr) {
    if (index === arr.length - 1) {
      acc[key] = value;
    }

    return acc[key];
  }, state);
}

function getNestedValue(state, dotKey) {
  return dotKey.split('.').reduce(function (acc, key) {
    return acc[key];
  }, state);
}