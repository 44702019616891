"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildAsyncReducers = buildAsyncReducers;

var _utils = require("../../utils");

function buildAsyncReducers(params) {
  var _params$itemKey = params.itemKey,
      itemKey = _params$itemKey === void 0 ? 'item' : _params$itemKey,
      _params$loadingKey = params.loadingKey,
      loadingKey = _params$loadingKey === void 0 ? 'loading' : _params$loadingKey,
      _params$errorKey = params.errorKey,
      errorKey = _params$errorKey === void 0 ? 'error' : _params$errorKey;

  function pending(state, _ref) {
    var type = _ref.type;
    (0, _utils.stateKeysExists)(state, [loadingKey, errorKey], type);
    (0, _utils.setNestedValue)(state, loadingKey, true);
    (0, _utils.setNestedValue)(state, errorKey, null);
  }

  var fulfilled = function fulfilled(state, _ref2) {
    var payload = _ref2.payload,
        type = _ref2.type;
    (0, _utils.stateKeysExists)(state, [loadingKey, errorKey], type);

    if (itemKey) {
      (0, _utils.stateKeyExists)(state, itemKey, type);
      (0, _utils.setNestedValue)(state, itemKey, payload);
    }

    (0, _utils.setNestedValue)(state, loadingKey, false);
    (0, _utils.setNestedValue)(state, errorKey, null);
  };

  var rejected = function rejected(state, _ref3) {
    var payload = _ref3.payload,
        type = _ref3.type;
    (0, _utils.stateKeysExists)(state, [loadingKey, errorKey], type);
    (0, _utils.setNestedValue)(state, loadingKey, false);
    (0, _utils.setNestedValue)(state, errorKey, payload);
  };

  return {
    pending: pending,
    fulfilled: fulfilled,
    rejected: rejected
  };
}