import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  ScrollView,
  Button,
  useToast,
  Stack,
  FormControl,
  Select,
  Heading,
  FlatList,
  Avatar,
  Spacer,
  Tooltip,
  Switch,
  Input,
  Icon,
  Image,
  Checkbox,
} from "native-base";
import { SimpleLineIcons, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { GlobalStyles } from "../../constants/styles";
import { GlobalConstants } from "../../constants/constants";
import { useNavigation } from "@react-navigation/native";
import Toast from "../../components/Toast";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ChangePassword from "../../components/ChangePassword";
import { truncateString, validEmail } from "../../utils/general";
import SetUser from "../../redux/user/SetUser";
import { debounce } from 'lodash';

const CitizenSettingsPage = () => {
  const dispatch = useDispatch();
  let session = useSelector((state) => state.user);
  console.log(session);
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const toast = useToast();
  const navigation = useNavigation();

  const [selectedCity, setSelectedCity] = useState();
  const [showInvite, setShowInvite] = useState(false);
  const [showInviteList, setShowInviteList] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showConnectionRequest, setShowConnectionRequest] = useState(false);
  const [inviteFriendData, setInviteFriendData] = useState([]);
  const [loadingEmails, setLoadingEmails] = useState(new Set());
 const [currentPage, setCurrentPage] = useState(1);
 const [loadingIds, setLoadingIds] = useState(new Set()); // Track loading state for each friend ID
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [isSearchingData, setIsSearchingData] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState({ name: '', email: '' });
  const [isChecked, setIsChecked] = useState(false);
 

  const [cityBtnTextColor, setCityBtnTextColor] = useState(
    GlobalStyles.colors.mustard
  );
  const [followedBusinessBtnTextColor, setFollowedBusinessBtnTextColor] =
    useState(GlobalStyles.colors.blue);

  const [searchAndFollowBtnTextColor, setSearchAndFollowBtnTextColor] =
    useState(GlobalStyles.colors.blue);
  const [eventsBtnTextColor, setEventsBtnTextColor] = useState(
    GlobalStyles.colors.blue
  );
  const [categoryBtnTextColor, setCategoryBtnTextColor] = useState(
    GlobalStyles.colors.blue
  );
  const [friendsBtnTextColor, setFriendsBtnTextColor] = useState(
    GlobalStyles.colors.blue
  );

  const [isCitySelected, setIsCitySelected] = useState(true);
  const [isFollowedBusinessSelected, setIsFollowedBusinessSelected] =
    useState(false);
  const [isSearchAndFollowSelected, setIsSearchAndFollowSelected] =
    useState(false);
  const [isEventSelected, setIsEventSelected] = useState(false);
  const [changePasswordSelected, setChangePasswordSelected] = useState(false);
  const [friendsSelected, setFriendsSelected] = useState(false);

  const [isEventsEnabled, setIsEventsEnabled] = useState(false);
  const [isOpeningsEnabled, setIsOpeningsEnabled] = useState(false);
  const [isDealsEnabled, setIsDealsEnabled] = useState(false);

  const [cities, setCities] = useState([]);

  const [noSearchBusinessResultText, setNoSearchBusinessResultText] =
    useState("");
  const [unfollowBusinesses, setUnfollowBusinesses] = useState([]);
  const [followedBusinesses, setFollowedBusinesses] = useState([]);

  const [businessSelectedId, setBusinessSelectedId] = useState();

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isDropdownFilter, setIsDropdownFilter] = useState(false);

  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessSubCategories, setBusinessSubCategories] = useState([]);
  // const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(0);

  const [selectedCategory, setSelectedCategories] = useState("");
  const [selectedSubCategory, setSelectedSubCategories] = useState("");

  const [inviteEmail, setInviteEmail] = useState("");
  const [isInviteSending, setIsInviteSending] = useState(false);
  const [invitedFriends, setInvitedFriends] = useState([]);
  const [invitedFriendSelectedId, setInvitedFriendSelectedId] = useState();

  const renderUnfollowBusinessesFooter = () => {
    return (
      //Footer View with Load More button
      <Box>
        {isDropdownFilter ? (
          <></>
        ) : isSearching ? (
          <></>
        ) : (
          <Button
            isLoading={isLoading}
            size="sm"
            variant="outline"
            onPress={unfollowBusinessesNextPage}
          >
            Load More
          </Button>
        )}
      </Box>
    );
  };

  const unfollowBusinessesNextPage = async () => {
    setIsSearching(false);
    setIsLoading(true);
    let data = await getUnfollowBusinesses(page);
    //After the response increasing the offset for the next API call.
    setUnfollowBusinesses([...unfollowBusinesses, ...data]);
    setPage(page + 1);
    setIsLoading(false);
  };

  const unfollowBusinessesPage1 = async () => {
    setIsSearching(false);
    setIsLoading(true);
    setUnfollowBusinesses([]);
    let data = await getUnfollowBusinesses(1);
    setUnfollowBusinesses(data);
    setPage(2);
    setIsLoading(false);
  };

  const onSearchTextChange = async (keyword) => {
    if (keyword) {
      setSelectedCategories("");
      setSelectedSubCategories("");
      setIsDropdownFilter(false);
      setBusinessSubCategories([]);
      setIsSearching(true);
      setUnfollowBusinesses([]);
      let data = await getUnfollowBusinesses(1, 100, keyword);
      setUnfollowBusinesses(data);
      if (data.length) {
        setNoSearchBusinessResultText("");
      } else {
        setNoSearchBusinessResultText(
          "Business you are searching is not yet our partner."
        );
      }
    } else {
      await unfollowBusinessesPage1();
    }
  };

  const getUnfollowBusinesses = async (page, offSet = 50, text = "") => {
    let data = [];
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}businesses?query=` +
          text +
          `&page=` +
          page +
          `&offSet=` +
          offSet
      );
      if (resp.data.status) {
        data = resp.data.data;
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getFollowedBusinesses = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}businesses/followed`
      );
      if (resp.data.status) {
        let data = resp.data.data;
        setFollowedBusinesses(data);
      } else {
        // toast.show({
        //   render: () => {
        //     return (
        //       <Toast
        //         color={GlobalStyles.colors.red}
        //         message={"Failed to get all businesses!!!"}
        //       />
        //     );
        //   },
        //   placement: "top",
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getAvatarChar(fullName) {
    let nameShortCode = "";
    if (fullName && fullName.split(" ").length > 1) {
      nameShortCode =
        fullName.split(" ")[0].charAt(0).toUpperCase() +
        fullName.split(" ")[1].charAt(0).toUpperCase();
    } else {
      nameShortCode = fullName.charAt(0).toUpperCase();
    }
    return nameShortCode;
  }

  function changeColor(btnType) {
    if (btnType === "FollowedBusiness") {
      setIsFollowedBusinessSelected(true);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(false);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setShowInvite(false);
      setShowInviteList(false);
      setShowSearch(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.mustard);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "SearchAndFollow") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(true);
      setIsCitySelected(false);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setShowInvite(false);
      setShowInviteList(false);
      setShowSearch(false);
      setShowConnectionRequest(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.mustard);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "City") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(true);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setShowInvite(false);
      setShowInviteList(false);
      setShowSearch(false);
      setShowConnectionRequest(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.mustard);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "Events") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(false);
      setIsEventSelected(true);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setShowInvite(false);
      setShowInviteList(false);
      setShowSearch(false);
      setShowConnectionRequest(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.mustard);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "ChangePassword") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(false);
      setIsEventSelected(false);
      setChangePasswordSelected(true);
      setFriendsSelected(false);
      setShowInvite(false);
      setShowInviteList(false);
      setShowSearch(false);
      setShowConnectionRequest(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.mustard);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "Friends") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(false);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(true);
      setShowInvite(false);
      setShowInviteList(false);
      setShowSearch(false);
      setShowConnectionRequest(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.mustard);
    } else {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(true);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setShowInvite(false);
      setShowInviteList(false);
      setShowSearch(false);
      setShowConnectionRequest(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.mustard);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    }
  }

  const getAllCities = async () => {
    try {
      const resp = await axios.get(`${GlobalConstants.townTicksBaseUrl}cities`);
      if (resp.data.status) {
        setCities(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get cities!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitCity = async () => {
    if (!selectedCity) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"kindly select city!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    }

    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/city`,
        { city_id: selectedCity }
      );
      if (resp.data.status) {
        dispatch(SetUser.action(null));
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"successfully saved!!!"}
              />
            );
          },
          placement: "top",
        });

        // console.log("old token", session.user.token);

        const updatedSession = {
          ...session,
          user: {
            ...session.user,
            token: resp.data.data,
          },
        };
        // console.log("new token", updatedSession.user.token);

        dispatch(SetUser.action(updatedSession.user));
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to save city!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onEventAndOpeningsFlag = async (obj) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/settings`,
        obj
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"successfully saved!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to save!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFollowBusiness = async (id) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/follow`,
        {
          following_id: id,
        }
      );
      if (resp.data.status) {
        updateBusinessStateOnFollowUnFollow(id, "follow");
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"successfully followed!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to follow business!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onUnfollowBusiness = async (id) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/unfollow`,
        {
          following_id: id,
        }
      );
      if (resp.data.status) {
        updateBusinessStateOnFollowUnFollow(id, "unfollow");
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"successfully unfollowed!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to unfollow business!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateBusinessStateOnFollowUnFollow = async (id, type) => {
    let tempBus = [];
    if (type == "follow") {
      tempBus = unfollowBusinesses;
      tempBus = tempBus.filter((obj) => {
        return obj.id !== id;
      });
      setUnfollowBusinesses(tempBus);
      setBusinessSelectedId(id);
    } else {
      tempBus = followedBusinesses;
      tempBus = tempBus.filter((obj) => {
        return obj.id !== id;
      });
      setFollowedBusinesses(tempBus);
      setBusinessSelectedId(id);
    }
  };

  const getSettings = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}users/settings`
      );
      if (resp.data.status) {
        setIsEventsEnabled(resp.data.data.is_events_display);
        setIsOpeningsEnabled(resp.data.data.is_openings_display);
        setIsDealsEnabled(resp.data.data.is_deals_display);
        setSelectedCity(resp.data.data.cityId.toString());
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get settings!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchByKeyword = (arr, keyword) => {
    const [a, b] = [[], []];
    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];

      if (el.business_profile) {
        if (
          el.business_profile.name.toLowerCase().includes(keyword.toLowerCase())
        ) {
          if (
            el.business_profile.name
              .toLowerCase()
              .startsWith(keyword.toLowerCase())
          )
            a.push(arr[i]);
          else b.push(arr[i]);
        }
      }
    }
    return [...a, ...b];
  };

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
    await getAllCities();
    await getSettings();
    await getAllBusinessCategories();
  }, []);

  const toggleEventsSwitch = async () => {
    setIsEventsEnabled((previousState) => !previousState);
    await onEventAndOpeningsFlag({
      is_events_display: !isEventsEnabled,
    });
  };

  const toggleOpeningsSwitch = async () => {
    setIsOpeningsEnabled((previousState) => !previousState);
    await onEventAndOpeningsFlag({
      is_openings_display: !isOpeningsEnabled,
    });
  };

  const toggleDealsSwitch = async () => {
    setIsDealsEnabled((previousState) => !previousState);
    await onEventAndOpeningsFlag({
      is_deals_display: !isDealsEnabled,
    });
  };

  const getAllBusinessCategories = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}businesses/category`
      );
      if (resp.data.status) {
        setBusinessCategories(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get business categories!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBusinessSubCategoriesById = async (type) => {
    try {
      if (type > 0) {
        setUnfollowBusinesses([]);
        setIsDropdownFilter(true);
        const resp = await axios.get(
          `${GlobalConstants.townTicksBaseUrl}businesses/subcategory/` + type
        );
        if (resp.data.status) {
          setBusinessSubCategories(resp.data.data);
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={"Failed to business sub categories!!!"}
                />
              );
            },
            placement: "top",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OnChangeSubCategoryGetUnfollowedBusiness = async (subCategoryId) => {
    try {
      if (subCategoryId > 0) {
        const resp = await axios.get(
          `${GlobalConstants.townTicksBaseUrl}businesses/v1/category/` +
            subCategoryId
        );
        if (resp.data.status) {
          setUnfollowBusinesses(resp.data.data);
          setIsDropdownFilter(true);
        } else {
          setUnfollowBusinesses([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SendInvite = async () => {
    setIsInviteSending(true);
    if (inviteEmail) {
      try {
        if (validEmail(inviteEmail)) {
          const resp = await axios.post(
            `${GlobalConstants.townTicksBaseUrl}friend/invite`,
            {
              invitee_email: inviteEmail,
            }
          );
          if (resp.data.status) {
            toast.show({
              render: () => {
                return (
                  <Toast
                    color={GlobalStyles.colors.green}
                    message={"Invite Send Successfully!!!"}
                  />
                );
              },
              placement: "top",
            });
            setInviteEmail("");
            await getInvitedFriends();
          } else {
            toast.show({
              render: () => {
                return (
                  <Toast
                    color={GlobalStyles.colors.red}
                    message={resp.data.status_msg}
                  />
                );
              },
              placement: "top",
            });
          }
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={"Invalid Email!!!"}
                />
              );
            },
            placement: "top",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsInviteSending(false); // Re-enable the button after response is received
      }
    } else {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"Please Enter Email!!!"}
            />
          );
        },
        placement: "top",
      });
    }
  };

  const getInvitedFriends = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}friend/invite`
      );
      if (resp.data.status) {
        setInvitedFriends(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get Invited Friends!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRemindInvitedFriend = async (id) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}friend/invite/reminder`,
        {
          invitee_id: id,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Successfully reminded!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to remind!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveInvitedFriend = async (id, isFriend) => {
    let tempInvitedFriends = [];
    try {
      const resp = await axios.delete(
        `${GlobalConstants.townTicksBaseUrl}friend`,
        {
          data: {
            id: id,
            is_friend: isFriend,
          },
        }
      );
      if (resp.data.status) {
        tempInvitedFriends = invitedFriends;
        tempInvitedFriends = tempInvitedFriends.filter((obj) => {
          return obj.id !== id;
        });
        setInvitedFriends(tempInvitedFriends);
        setInvitedFriendSelectedId(id);
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Successfully removed!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to remove friend!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleShowInvite = () => {
    setFriendsSelected(false);
    setShowInvite((prev) => !prev); // Toggle content display
    setShowInviteList(false);
    setShowSearch(false);
    setShowConnectionRequest(false);
  };
  const handleShowInviteList =() =>{
    getInvitedFriends();
    setFriendsSelected(false);
    setShowInviteList((prev) => !prev);
    setShowInvite(false);
    setShowSearch(false);
    setShowConnectionRequest(false);
  };
  const handleShowSearch =() =>{
    setFriendsSelected(false);
    setShowSearch((prev) => !prev);
    setShowInvite(false);
    setShowInviteList(false);
    setShowConnectionRequest(false);
  };
  const handleMainMenu =() =>{
    setFriendsSelected(true);
    setShowSearch(false);
    setShowInvite(false);
    setShowInviteList(false);
    setShowConnectionRequest(false);
    
  };

  const getConnectionRequest = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${GlobalConstants.townTicksBaseUrl}friend/invitations?page=${page}`);
      setLoading(false);
      return response.data.status ? response.data.data : [];
    } catch (error) {
      console.log('API Error:', error);
      setLoading(false);
      return [];
    }
  };

  const handleConnectionRequest = async () => {
    setFriendsSelected(false);
    setShowSearch(false);
    setShowInvite(false);
    setShowInviteList(false);
    setShowConnectionRequest((prev) => !prev);
    if (!showConnectionRequest) {
      const initialData = await getConnectionRequest(1);
      setInviteFriendData(initialData);
      setCurrentPage(2); // Update to use 'currentPage'
    }
  };

  const loadMoreData = async () => {
    if (loading) return;
    const newData = await getConnectionRequest(currentPage);
    if (newData.length > 0) {
      setInviteFriendData((prevData) => [...prevData, ...newData]);
      setCurrentPage(currentPage + 1); // Update to use 'currentPage'
    }
  };

  // Connect invitation Accept
  const acceptInvitation = async (id) => {
    try {
      // Add the ID to the loading set to show loading status
      setLoadingIds((prev) => new Set(prev).add(id));

      const response = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}friend/accept/invitation`,
        { id } 
      );

      console.log(response.data.status, id, ": API hit");
      if (response.data.status) {
        setInviteFriendData((prevData) => prevData.filter((friend) => friend.id !== id));
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
    } finally {
      setLoadingIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

   // Function to send an invitation
   const handleSendInvitation = async (id) => {
    try {
      // Add the recipientEmail to the loading set
      setLoadingEmails((prev) => new Set(prev).add(id));

      // Send the API request with recipient_email as the payload
      const response = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}friend/${id}/invite`,
        
      );

      console.log(response.data.status, id, ": API request sent");

      // Check if the API response was successful
      if (response.data.status) {
        // Optionally, remove the friend from the list after accepting
        setSearchResults((prevList) =>
          prevList.filter((friend) => friend.id !== id)
        );
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
    } finally {
      // Remove the recipientEmail from the loading set
      setLoadingEmails((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

// Fetch function for searching by name with pagination
const fetchFriendsByName = useCallback(
  debounce(async (name, currentPage = 1) => {
    if (!name.trim()) return;
    setIsSearchingData(true);
    try {
      const resp = await axios.get(`${GlobalConstants.townTicksBaseUrl}friend/search?name=${name}&page=${currentPage}`);
      const data = resp.data.data || [];

      if (currentPage === 1) {
        setSearchResults(data);
      } else {
        setSearchResults((prev) => [...prev, ...data]);
      }

      // If fewer than 10 items were returned, assume no more data
      setHasMoreData(data.length === 10);
    } catch (error) {
      console.log("API Error:", error);
    } finally {
      setIsSearchingData(false);
    }
  }, 300),
  []
);

// Fetch function for searching by email with pagination
const fetchFriendsByEmail = useCallback(
  debounce(async (email, currentPage = 1) => {
    if (!email.trim()) return;
    setIsSearchingData(true);
    try {
      const resp = await axios.get(`${GlobalConstants.townTicksBaseUrl}friend/search?email=${email}&page=${currentPage}`);
      const data = resp.data.data || [];

      if (currentPage === 1) {
        setSearchResults(data);
      } else {
        setSearchResults((prev) => [...prev, ...data]);
      }

      setHasMoreData(data.length === 10);
    } catch (error) {
      console.log("API Error:", error);
    } finally {
      setIsSearchingData(false);
    }
  }, 300),
  []
);

// Handle input change and reset results when input is cleared
const handleInputChange = (value, type) => {
  setSearchQuery((prevQuery) => ({ ...prevQuery, [type]: value }));
  setPage(1); // Reset to first page on new query

  if (!value.trim()) {
    setSearchResults([]); // Clear results if input is empty
    if (type === 'name') fetchFriendsByName.cancel();
    if (type === 'email') fetchFriendsByEmail.cancel();
    setHasMoreData(false);
    return;
  }

  if (type === 'name') {
    fetchFriendsByName(value, 1);
  } else if (type === 'email') {
    fetchFriendsByEmail(value, 1);
  }
};

// Load more data for additional pages
const loadMoreSearchData = () => {
  const nextPage = page + 1;
  setPage(nextPage);

  if (searchQuery.name) {
    fetchFriendsByName(searchQuery.name, nextPage);
  } else if (searchQuery.email) {
    fetchFriendsByEmail(searchQuery.email, nextPage);
  }
};
 
    
    const handleCheckboxChange = async () => {
      setIsChecked(!isChecked);
      console.log("invited id",invitedFriends)
      const nonFriendIds = invitedFriends
      .filter((friend) => !friend.isFriend)  // Filter out friends where isFriend is false
      .map((friend) => friend.id);           // Map to extract the IDs only

      console.log("Non-Friend IDs:", nonFriendIds);
      if (!isChecked) {
        // Call the API when the checkbox is checked
        try {
          const response = await axios.post(
            `${GlobalConstants.townTicksBaseUrl}v1/friend/invite/reminder`,
            {
              invitee_ids: nonFriendIds,
            }
          );
          console.log('API Response:', response.data,response.data.status);
    
          if (response.data.status) {
            toast.show({
              render: () => {
                return (
                  <Toast
                    color={GlobalStyles.colors.green}
                    message={"Reminder sent successfully!"}
                  />
                );
              },
              placement: "top",
              duration: 3000, // Toast duration in milliseconds
            });
          } else {
            // If response status is not success, show an error message
            toast.show({
              render: () => (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={"Failed to send reminder"}
                />
              ),
              placement: "top",
              duration: 3000,
            });
          }
        } catch (error) {
          console.error('Error calling API:', error);
    
          toast.show({
            render: () => (
              <Toast
                color={GlobalStyles.colors.red}
                message={"An error occurred while sending reminder"}
              />
            ),
            placement: "top",
            duration: 3000,
          });
        } finally {
          // Uncheck the checkbox after the API call, regardless of success or failure
          setIsChecked(false);
        }
      }
    };

  return (
    <>
      <HStack space={3} justifyContent="center">
        <Center w={[75, 150, 220]} rounded="md">
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("City");
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={cityBtnTextColor}
            >
              City
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("FollowedBusiness");
              getFollowedBusinesses();
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={followedBusinessBtnTextColor}
            >
              Business{"\n"}
              Followed
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={async () => {
              changeColor("SearchAndFollow");
              await unfollowBusinessesPage1();
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={searchAndFollowBtnTextColor}
            >
              Search &{"\n"}
              Follow
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("Events");
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={eventsBtnTextColor}
            >
              Feeds {"\n"}
              On/Off
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("Friends");
              getInvitedFriends();
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={friendsBtnTextColor}
            >
              Friends
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("ChangePassword");
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={categoryBtnTextColor}
            >
              Change{"\n"}
              Password
            </Text>
          </Button>
        </Center>
        <Center h={360} w={[225, 400, 630]} rounded="md" shadow={3}>
          {isCitySelected ? (
            <>
              <Box>
                <FormControl>
                  <Center>
                    <Select
                      minWidth="200"
                      maxWidth="400"
                      accessibilityLabel="Choose City"
                      placeholder="Choose City"
                      _selectedItem={{
                        bg: "teal.600",
                      }}
                      mt="1"
                      px="1"
                      onValueChange={(x) => {
                        setSelectedCity(x);
                      }}
                      selectedValue={selectedCity}
                    >
                      {cities.map((x, index) => {
                        return (
                          <Select.Item
                            key={index}
                            label={x.title.toString()}
                            value={x.id.toString()}
                          />
                        );
                      })}
                    </Select>
                  </Center>
                </FormControl>
                <Center>
                  <Button
                    w={12}
                    mt="5"
                    size="sm"
                    variant={"solid"}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={GlobalStyles.colors.mustard}
                    borderWidth={2}
                    onPress={onSubmitCity}
                  >
                    Save
                  </Button>
                </Center>
              </Box>
            </>
          ) : (
            <></>
          )}
          {isFollowedBusinessSelected ? (
            <>
              <ScrollView>
                <Box>
                  <Heading fontSize="sm" p="4" pb="3">
                    Business Followed
                  </Heading>
                  <FlatList
                    extraData={businessSelectedId}
                    data={followedBusinesses}
                    renderItem={({ item }) => (
                      <Box
                        borderBottomWidth="1"
                        _dark={{
                          borderColor: "muted.50",
                        }}
                        borderColor="muted.300"
                        pl={["0", "4"]}
                        pr={["0", "5"]}
                        py="2"
                      >
                        <HStack space={[2, 3]} justifyContent="space-between">
                          <Avatar
                            size="sm"
                            source={{
                              uri: item.logo_url,
                            }}
                            bg={GlobalStyles.colors.blue}
                          >
                            {item.business_profile
                              ? getAvatarChar(item.business_profile?.name)
                              : ""}
                          </Avatar>
                          <VStack>
                            <Text
                              _dark={{
                                color: "warmGray.50",
                              }}
                              color="coolGray.800"
                              // numberOfLines={2}
                              bold
                            >
                              {window?.innerWidth < 415 &&
                              item.business_profile &&
                              item.business_profile?.name
                                ? item.business_profile?.name?.split(" ")[0] +
                                  // " " +
                                  // item.business_profile?.name
                                  //   ?.split(" ")[1]
                                  //   .substring(0, 5) +
                                  "..."
                                : item.business_profile?.name}
                            </Text>
                          </VStack>
                          <Spacer />

                          <Tooltip label="unfollow" openDelay={100}>
                            <Button
                              bg={GlobalStyles.colors.blue}
                              size={"sm"}
                              alignSelf="flex-start"
                              borderRadius={20}
                              onPress={() => onUnfollowBusiness(item.id)}
                            >
                              <Text
                                fontWeight="bold"
                                color={GlobalStyles.colors.white}
                              >
                                unfollow
                              </Text>
                            </Button>
                          </Tooltip>
                        </HStack>
                      </Box>
                    )}
                    keyExtractor={(item) => item.id}
                  />
                </Box>
              </ScrollView>
            </>
          ) : (
            <></>
          )}
          {isSearchAndFollowSelected ? (
            <>
              <ScrollView>
                <Box>
                  <Heading fontSize="sm" p="4" pb="3">
                    Search & Follow Business
                  </Heading>
                  <Center>
                    <Input
                      w={[180, 200, 250]}
                      placeholder="Search ..."
                      variant="filled"
                      borderRadius="10"
                      mt="1"
                      py="1"
                      px="2"
                      InputLeftElement={
                        <Icon
                          ml="2"
                          size="4"
                          color="gray.400"
                          as={<Ionicons name="ios-search" />}
                        />
                      }
                      onChangeText={onSearchTextChange}
                    />
                    <Text w={[180, 200, 250]}>
                      {noSearchBusinessResultText}
                    </Text>
                    <Select
                      w={[180, 200, 250]}
                      accessibilityLabel="Select Business Category"
                      placeholder="Select Business Category"
                      _selectedItem={{
                        bg: "teal.600",
                      }}
                      mt="1"
                      borderRadius="10"
                      onValueChange={(x) => {
                        getAllBusinessSubCategoriesById(x);
                        setSelectedCategories(x);
                      }}
                      selectedValue={selectedCategory}
                    >
                      {businessCategories.map((x, index) => {
                        return (
                          <Select.Item
                            key={index}
                            label={x.title.toString()}
                            value={x.id.toString()}
                          />
                        );
                      })}
                    </Select>
                    <Select
                      w={[180, 200, 250]}
                      accessibilityLabel="Select Business Sub Category"
                      placeholder="Select Business Sub Category"
                      _selectedItem={{
                        bg: "teal.600",
                      }}
                      mt="1"
                      borderRadius="10"
                      onValueChange={(x) => {
                        OnChangeSubCategoryGetUnfollowedBusiness(x);
                        setSelectedSubCategories(x);
                      }}
                      selectedValue={selectedSubCategory}
                    >
                      {businessSubCategories.map((x, index) => {
                        return (
                          <Select.Item
                            key={index}
                            label={x.title.toString()}
                            value={x.id.toString()}
                          />
                        );
                      })}
                    </Select>
                  </Center>
                  <FlatList
                    extraData={businessSelectedId}
                    data={unfollowBusinesses}
                    renderItem={({ item }) => (
                      <Box
                        borderBottomWidth="1"
                        _dark={{
                          borderColor: "muted.50",
                        }}
                        borderColor="muted.300"
                        pl={["0", "4"]}
                        pr={["0", "5"]}
                        py="2"
                      >
                        <HStack space={[2, 3]} justifyContent="space-between">
                          <Avatar
                            size="sm"
                            source={{
                              uri: item.logo_url,
                            }}
                            bg={GlobalStyles.colors.blue}
                          >
                            {item.business_profile
                              ? getAvatarChar(item.business_profile?.name)
                              : ""}
                          </Avatar>
                          <VStack>
                            <Text
                              _dark={{
                                color: "warmGray.50",
                              }}
                              color="coolGray.800"
                              // numberOfLines={2}
                              bold
                            >
                              {window?.innerWidth < 415 &&
                              item.business_profile &&
                              item.business_profile?.name
                                ? item.business_profile?.name?.split(" ")[0] +
                                  // " " +
                                  // item.business_profile?.name
                                  //   ?.split(" ")[1]
                                  //   .substring(0, 5) +
                                  "..."
                                : item.business_profile?.name}
                            </Text>
                          </VStack>
                          <Spacer />
                          <Tooltip label="follow" openDelay={100}>
                            <Button
                              bg={GlobalStyles.colors.blue}
                              size={"sm"}
                              alignSelf="flex-start"
                              borderRadius={20}
                              onPress={() => onFollowBusiness(item.id)}
                            >
                              <Text
                                fontWeight="bold"
                                color={GlobalStyles.colors.white}
                              >
                                follow{" "}
                              </Text>
                            </Button>
                          </Tooltip>
                        </HStack>
                      </Box>
                    )}
                    keyExtractor={(item) => item.id}
                    ListFooterComponent={renderUnfollowBusinessesFooter}
                  />
                </Box>
              </ScrollView>
            </>
          ) : (
            <></>
          )}
          {isEventSelected ? (
            <>
              <Center>
                <Heading fontSize="md">Enable Events</Heading>
                <Switch
                  size="lg"
                  mt="3"
                  defaultIsChecked={isEventsEnabled}
                  colorScheme="emerald"
                  onValueChange={toggleEventsSwitch}
                  value={isEventsEnabled}
                />
                <Heading mt="10" fontSize="md">
                  Enable Openings
                </Heading>
                <Switch
                  size="lg"
                  mt="3"
                  defaultIsChecked={isOpeningsEnabled}
                  colorScheme="emerald"
                  onValueChange={toggleOpeningsSwitch}
                  value={isOpeningsEnabled}
                />
                <Heading mt="10" fontSize="md">
                  Enable Deals
                </Heading>
                <Switch
                  size="lg"
                  mt="3"
                  defaultIsChecked={isDealsEnabled}
                  colorScheme="emerald"
                  onValueChange={toggleDealsSwitch}
                  value={isDealsEnabled}
                />
              </Center>
            </>
          ) : (
            <></>
          )}

          {friendsSelected ? (
            <>
              
              <Center>
                <Button
                    onPress={handleShowInvite}
                    borderRadius="50px"
                    bg="gray"
                    width="360px"
                    _text={{ color: "#000000" }}
                    variant={"outline"}
                    my="1"
                    px="1"
                    shadow={3}
                  >
                    <HStack alignItems="center" space={10} >
                      
                    <Text fontFamily={GlobalStyles.text.fontFamily}
                        color={cityBtnTextColor} fontSize="18px">
                          Invite Friends to TownTicks</Text>
                      <Image
                          source={require("../../../assets/settings/friend.png")}
                          alt="Icon"
                          size={6}
                        />
                    </HStack>
                  </Button>
                  <Button
                    onPress={handleShowInviteList}
                    borderRadius="50px"
                    bg="gray"
                    width="360px"
                    _text={{ color: "#000000" }}
                    variant={"outline"}
                    my="1"
                    px="1"
                    shadow={3}
                  >
                    <HStack alignItems="center" space={10} >
                      
                      <Text fontFamily={GlobalStyles.text.fontFamily}
                        color={cityBtnTextColor} fontSize="18px">
                        Invited & Connected Friends</Text>
                      <Image
                          source={require("../../../assets/settings/friends.png")}
                          alt="Icon"
                          size={6}
                        />
                    </HStack>
                  </Button>
                  <Button
                    onPress={handleShowSearch}
                    borderRadius="50px"
                    bg="gray"
                    width="360px"
                    _text={{ color: "#000000" }}
                    variant={"outline"}
                    my="1"
                    px="1"
                    shadow={3}
                  >
                    <HStack alignItems="center" space={10} >
                      
                    <Text fontFamily={GlobalStyles.text.fontFamily}
                        color={cityBtnTextColor} fontSize="18px">
                          Find Friends on TownTicks</Text>
                      <Image
                          source={require("../../../assets/settings/search.png")}
                          alt="Icon"
                          size={6}
                        />
                    </HStack>
                  </Button>
                  <Button
                    onPress={handleConnectionRequest}
                    borderRadius="50px"
                    bg="gray"
                    width="360px"
                    _text={{ color: "#000000" }}
                    variant={"outline"}
                    my="1"
                    px="1"
                    shadow={3}
                  >
                    <HStack alignItems="center" space={10} >
                      
                     <Text fontFamily={GlobalStyles.text.fontFamily}
                        color={cityBtnTextColor} fontSize="18px">
                          Connection Requests</Text>
                      <Image
                          source={require("../../../assets/settings/contact-friends.png")}
                          alt="Icon"
                          size={6}
                        />
                    </HStack>
                  </Button>
              </Center>
            </>
          ) : (
            <></>
          )}
          {showInvite ?(
            <>
            <Button alignSelf="self-start"
                   width="50px" height="22px" 
                   position = "absolute"
                   top= "5px"
                   backgroundColor={GlobalStyles.colors.red}
                   ml={4}
                   onPress={handleMainMenu}
                  
            >
              <Text fontFamily={GlobalStyles.text.fontFamily}
                            color={GlobalStyles.colors.white} fontSize="12px">
                              Back</Text>
            </Button>
            <Center>
               <Button
                    
                    borderRadius="50px"
                    bg="gray"
                    width="400px"
                    _text={{ color: "#000000" }}
                    variant={"outline"}
                    my="1"
                    px="1"
                    shadow={3}
                    borderColor="#FFC000" // Border color for HStack
                    borderWidth={1} // Border width for HStack
                  >
                        <HStack alignItems="center" space={10} >
                          
                        <Text fontFamily={GlobalStyles.text.fontFamily}
                            color={cityBtnTextColor} fontSize="18px">
                              Invite Friends to TownTicks</Text>
                          <Image
                              source={require("../../../assets/settings/friend-fill.png")}
                              alt="Icon"
                              size={6}
                              ml={10}
                            />
                        </HStack>
                </Button>
               <VStack className="friendSettings" space={4} mt={4}>
                <Center>
                  <Text fontSize="sm">
                    Invite Your friends to TownTicks & share Ticks with them.
                  </Text>
                </Center>
                <Center h={110} w={[200, 300, 450]} rounded="md" shadow={3}>
                  <Image
                    source={require("../../../assets/InviteLogo.png")}
                    alt="Invite"
                    size={50}
                  />
                  <HStack w={[150, 250, 400]} space={3}>
                    <Stack w={[100, 200, 300]}>
                      <FormControl>
                        <Input
                          type="text"
                          onChange={(e) => setInviteEmail(e.target.value)}
                          value={inviteEmail}
                          placeholder="Write Email"
                        />
                      </FormControl>
                    </Stack>
                    <Stack w={[50, 50, 100]}>
                      <FormControl>
                        <Button
                          h={36}
                          size="xs"
                          variant="outline"
                          borderColor="mustard"
                          onPress={SendInvite}
                          isDisabled={isInviteSending} // Disable button while sending
                        >
                          <Text fontFamily="your-font-family" color="blue" fontWeight="bold">
                          {isInviteSending ? 'Sending..' : 'Invite'}
                          </Text>
                        </Button>
                      </FormControl>
                    </Stack>
                  </HStack>
                </Center>
              </VStack>
            </Center>
            </>
          ) : (
            <></>
          )
          }
          {showInviteList ?(
            <>
            <Button alignSelf="self-start"
                   width="50px" height="22px"
                   position = "absolute"
                   top= "5px"
                   backgroundColor={GlobalStyles.colors.red}
                   ml={4}
                   onPress={handleMainMenu}
                  
            >
              <Text fontFamily={GlobalStyles.text.fontFamily}
                            color={GlobalStyles.colors.white} fontSize="12px">
                              Back</Text>
            </Button>
            <Center>
                    <Button
                    
                    borderRadius="50px"
                    bg="gray"
                    width="400px"
                    _text={{ color: "#000000" }}
                    variant={"outline"}
                    my="1"
                    px="1"
                    shadow={3}
                    borderColor="#FFC000" // Border color for HStack
                    borderWidth={1} // Border width for HStack
                  >
                        <HStack alignItems="center" space={10} >
                          
                        <Text fontFamily={GlobalStyles.text.fontFamily}
                            color={cityBtnTextColor} fontSize="18px">
                              Invited & Connected Friends</Text>
                          <Image
                              source={require("../../../assets/settings/friends-fill.png")}
                              alt="Icon"
                              size={6}
                              ml={10}
                            />
                        </HStack>
                    </Button>
                <ScrollView>
                  <Box maxHeight="280px" // Set the height limit for scroll
                      overflowY="scroll" // Enable vertical scrolling
                      >
                    <HStack alignItems="center" space={2} mt={2}>
                      <Checkbox 
                        isChecked={isChecked} 
                        onChange={handleCheckboxChange} 
                        colorScheme="yellow"
                      />
                      <Text fontSize="sm" color="black">
                        Remind invited friends who have not joined
                      </Text>
                  </HStack>
                    {invitedFriends.length > 0 ? (
                      <FlatList
                        extraData={invitedFriendSelectedId}
                        data={invitedFriends}
                        renderItem={({ item }) => (
                          <Box
                            borderBottomWidth="1"
                            _dark={{
                              borderColor: "muted.50",
                            }}
                            borderColor="muted.300"
                            pl={["0", "4"]}
                            pr={["0", "5"]}
                            py="2"
                          >
                            <HStack
                              space={[2, 3]}
                              justifyContent="space-between"
                            >
                              <VStack>
                                <Text
                                  _dark={{
                                    color: "warmGray.50",
                                  }}
                                  color="coolGray.800"
                                >
                                  {item.isFriend
                                    ? `${item.friend?.email} (${item.friend?.citizen_profile?.name || ''})` // Show email and name
                                    : window?.innerWidth < 415 && item.email
                                    ? truncateString(item.email, 10)
                                    : truncateString(item.email)
                                    }
                                </Text>
                              </VStack>
                              <Spacer />
                              {item.isFriend ? (
                                <>
                                  <MaterialIcons
                                    name="group"
                                    size={24}
                                    color={GlobalStyles.colors.blue}
                                  />
                                </>
                              ) : window?.innerWidth < 415 ? (
                                <>
                                  <MaterialIcons
                                    name="notifications-none"
                                    size={24}
                                    color={GlobalStyles.colors.blue}
                                    onPress={() =>
                                      onRemindInvitedFriend(item.id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <Tooltip label="Remind" openDelay={100}>
                                    <Button
                                      size={"xxs"}
                                      px={1}
                                      alignSelf="flex-start"
                                      borderRadius={5}
                                      variant={"outline"}
                                      borderColor={GlobalStyles.colors.mustard}
                                      borderWidth={1}
                                      onPress={() =>
                                        onRemindInvitedFriend(item.id)
                                      }
                                    >
                                      <Text
                                        color={GlobalStyles.colors.blue}
                                        fontWeight={"bold"}
                                      >
                                        Remind
                                      </Text>
                                    </Button>
                                  </Tooltip>
                                </>
                              )}
                              <Tooltip label="Remove" openDelay={100}>
                                <Button
                                  size={"xxs"}
                                  px={1}
                                  alignSelf="flex-start"
                                  borderRadius={5}
                                  variant={"outline"}
                                  borderColor={GlobalStyles.colors.mustard}
                                  borderWidth={1}
                                  onPress={() =>
                                    onRemoveInvitedFriend(
                                      item.id,
                                      item.isFriend
                                    )
                                  }
                                >
                                  <Text
                                    color={GlobalStyles.colors.blue}
                                    fontWeight={"bold"}
                                  >
                                    Remove
                                  </Text>
                                </Button>
                              </Tooltip>
                            </HStack>
                          </Box>
                        )}
                        keyExtractor={(item) => item.id}
                      />
                    ) : (
                      <>
                        <Center mt={5}>
                          <Text>No Data Available</Text>
                        </Center>
                      </>
                    )}
                  </Box>
                </ScrollView>
              </Center>
            </>

          ): (
            <></>
          )}
          {showSearch ?(
            <>
            <Button alignSelf="self-start"
                   width="50px" height="22px"
                   position = "absolute"
                   top= "5px"
                   backgroundColor={GlobalStyles.colors.red}
                   ml={4}
                   onPress={handleMainMenu}
                  
            >
              <Text fontFamily={GlobalStyles.text.fontFamily}
                            color={GlobalStyles.colors.white} fontSize="12px">
                              Back</Text>
            </Button>
              <Center>
                  <Button
                        
                        borderRadius="50px"
                        bg="gray"
                        width="400px"
                        borderColor={GlobalStyles.colors.mustard}
                        _text={{ color: "#000000" }}
                        variant={"outline"}
                        my="1"
                        px="1"
                        shadow={3}
                      >
                        <HStack alignItems="center" space={10} >
                          
                        <Text fontFamily={GlobalStyles.text.fontFamily}
                            color={cityBtnTextColor} fontSize="18px">
                              Find Friends on TownTicks</Text>
                          <Image
                              source={require("../../../assets/settings/search-fill.png")}
                              alt="Icon"
                              size={6}
                              ml={10}
                            />
                        </HStack>
                    </Button>
                {/* Search by Name Input */}
                      <HStack
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="50px"
                        width="400px"
                        borderColor="#FFC000"
                        borderWidth={1}
                        shadow={3}
                        mt={4}
                      >
                        <Input
                          placeholder="Enter Username"
                          variant="unstyled"
                          fontSize="18px"
                          px="4"
                          color="#000000"
                          flex={1}
                          value={searchQuery.name}
                          onChangeText={(value) => handleInputChange(value, 'name')}
                        />
                        <Image
                          source={require("../../../assets/settings/search-fill.png")}
                          alt="Icon"
                          size={6}
                          mr={3}
                        />
                      </HStack>

                      {/* Search by Email Input */}
                      <HStack
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="50px"
                        width="400px"
                        borderColor="#FFC000"
                        borderWidth={1}
                        shadow={3}
                        mt={4}
                      >
                        <Input
                          placeholder="Enter Email"
                          variant="unstyled"
                          fontSize="18px"
                          px="4"
                          color="#000000"
                          flex={1}
                          value={searchQuery.email}
                          onChangeText={(value) => handleInputChange(value, 'email')}
                        />
                        <Image
                          source={require("../../../assets/settings/search-fill.png")}
                          alt="Icon"
                          size={6}
                          mr={3}
                        />
                      </HStack>
               {/* Search Results with Lazy Loading */}
                    <ScrollView>
                      <Box space={1} width="360px" borderColor="coolGray.300" borderWidth={1} borderRadius="4px" bg="white" shadow={2} maxHeight="170px" overflowY="scroll" p={2} mt={2}>
                        <VStack >
                          {searchResults.length > 0 ? (
                            searchResults.map((result, index) => (
                              <HStack key={index} justifyContent="space-between" borderBottomWidth="1px" borderBottomColor="coolGray.300" p={2}>
                                <Text fontSize="sm" color="black">
                                  {result.name || result.email}
                                </Text>
                                <Button size="sm" height="25px" bg="#FEFEFF" borderColor="#FFC000" 
                                borderWidth="1px" _text={{ color: "#002061BD" }} 
                                _hover={{ bg: "#FFC000", 
                                _text: { color: "#FEFEFF" }
                                 }}
                                 onPress={() => handleSendInvitation(result.id)}
                                isDisabled={loadingEmails.has(result.id)} // Disable button if the invitation is being processed
                                isLoading={loadingEmails.has(result.id)}  // Show loading indicator on the button
                                 >
                                  Connect
                                </Button>
                              </HStack>
                            ))
                          ) : (
                            <Text color="gray.500" textAlign="center" mt={2}>No results found</Text>
                          )}
                        </VStack>

                        {/* Show 'Load More' button if there are more items to load */}
                        {hasMoreData && (
                          <Button onPress={loadMoreSearchData} mt={4} width="80px"
                           bg={GlobalStyles.colors.mustard}  _text={{ color: "#FFFFFF" }} 
                           isLoading={isSearching} isLoadingText="Loading..."
                           alignSelf="center"
                           >
                            <Text color={GlobalStyles.colors.white} fontSize="14px">Load More</Text>
                          </Button>
                        )}
                      </Box>
                    </ScrollView>

              </Center>
            </>
          ) : (
            <></>
          )
          }
          {showConnectionRequest ? (
            <>
            <Button alignSelf="self-start"
                   width="50px" height="22px"
                   position = "absolute"
                   top= "5px"
                   backgroundColor={GlobalStyles.colors.red}
                   ml={4}
                   onPress={handleMainMenu}
                  
            >
              <Text fontFamily={GlobalStyles.text.fontFamily}
                            color={GlobalStyles.colors.white} fontSize="12px">
                              Back</Text>
            </Button>
            <Center>
                <HStack
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50px"
                  width="400px"
                  height="40px"
                  borderColor="#FFC000" // Border color for HStack
                  borderWidth={1} // Border width for HStack
                  shadow={3}
                  mt={4}
                >
                  <Text
                    
                    variant="unstyled" // Removes default borders and background
                    fontSize="18px"
                    px="4"
                    flex={1} // Allows Input to take up available space within HStack
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={friendsBtnTextColor}
                  >Connection Requests</Text>
                  <Image
                    source={require("../../../assets/settings/friends-fill.png")}
                    alt="Icon"
                    size={6}
                    mr={3}
                  />
                </HStack>

               <Box 
                      maxHeight="170px" // Set the height limit for scroll
                      overflowY="scroll" // Enable vertical scrolling
                      width="410px"
                      ml={2}
                >
                {inviteFriendData.map((friend) => (
                  <HStack
                    key={friend.id}
                    justifyContent="space-between"
                    width="100%"
                    borderColor="coolGray.300"
                    borderBottomWidth="1px"
                    p={2}
                    borderRadius="10px"
                  >
                    <Text fontSize="sm" color="black" ml={2}>
                      {friend.name || friend.email}
                    </Text>
                    <Button
                      size="sm"
                      height="25px"
                      bg="#FEFEFF"
                      mr={6}
                      borderColor="#FFC000"
                      borderWidth="1px"
                      _text={{ color: "#002061BD" }}
                      _hover={{
                        bg: "#FFC000",
                        _text: { color: "#FEFEFF" },
                      }}
                      onPress={() => acceptInvitation(friend.id)}
                      isDisabled={loadingIds.has(friend.id)} // Disable button if the invitation is being processed
                      isLoading={loadingIds.has(friend.id)}  // Show loading indicator on the button
                    >
                      Connect
                    </Button>
                  </HStack>
                ))}
                {inviteFriendData.length > 0 && inviteFriendData.length % 10 === 0 && (
                  <Button onPress={loadMoreData} mt={4} width="80px"
                  bg={GlobalStyles.colors.mustard}  _text={{ color: "#FFFFFF" }} 
                  isLoading={isSearching} isLoadingText="Loading..."
                  alignSelf="center"
                  >
                   <Text color={GlobalStyles.colors.white} fontSize="14px">Load More</Text>
                 </Button>
                )}
               </Box> 
                
            </Center>
            </>
          ):(
            <></>
          )}

          {changePasswordSelected ? <ChangePassword /> : <></>}
        </Center>
      </HStack>
    </>
  );
};

export default CitizenSettingsPage;
