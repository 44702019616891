"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default(scope) {
  if (scope) {
    return _defineProperty({}, scope, {
      loading: false,
      error: null
    });
  }

  return {
    loading: false,
    error: null
  };
};

exports["default"] = _default;