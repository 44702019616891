import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  ScrollView,
  Button,
  useToast,
  Stack,
  TextArea,
  View,
  FormControl,
  Pressable,
  Input,
  Icon,
} from "native-base";
import { GlobalStyles } from "../../constants/styles";
import FeedCard from "../../components/FeedCard";
import { GlobalConstants } from "../../constants/constants";
import { useNavigation } from "@react-navigation/native";
import Toast from "../../components/Toast";
import { useSelector } from "react-redux";
import axios from "axios";
import ConfigureSettingsPopup from "../../components/ConfigureSettingsPopup";
import { uploadImage } from "../../utils/general";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import FeedCardNew from "../../components/FeedCardNew";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill's CSS


const BusinessMainPage = () => {
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const toast = useToast();
  const navigation = useNavigation();

  const [postTextArea, setPostTextArea] = useState("");
  const [postDetails, setPostDetails] = useState("");
  const [postType, setPostType] = useState(
    GlobalConstants.postCategories.tick.id
  );
  const [postImage, setPostImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editorContent, setEditorContent] = useState("");

  const [ticksBtnTextColor, setTicksBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [dealsBtnTextColor, setDealsBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [addTickBtnTextColor, setAddTickBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [addDealBtnTextColor, setAddDealBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [createAdBtnTextColor, setCreateAdBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [isTicksView, setIsTicksView] = useState(false);
  const [businessPosts, setBusinessPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [selectedPostCategoryId, setSelectedPostCategoryId] = useState(0);

  const [deletePopUp, setDeletePopUp] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const [remainingCharacters, setRemainingCharacters] = useState(300);
  const [remainingCharactersForDetail, setRemainingCharactersForDetail] = useState(1500);

  function changeColor(btnType) {
    if (btnType === "AddTick") {
      setTicksBtnTextColor(GlobalStyles.colors.white);
      setAddTickBtnTextColor(GlobalStyles.colors.mustard);
      setCreateAdBtnTextColor(GlobalStyles.colors.white);
      setAddDealBtnTextColor(GlobalStyles.colors.white);
      setDealsBtnTextColor(GlobalStyles.colors.white);
      setIsTicksView(false);
      setPostType(GlobalConstants.postCategories.tick.id);
    } else if (btnType === "AddDeal") {
      setTicksBtnTextColor(GlobalStyles.colors.white);
      setAddTickBtnTextColor(GlobalStyles.colors.white);
      setCreateAdBtnTextColor(GlobalStyles.colors.white);
      setAddDealBtnTextColor(GlobalStyles.colors.mustard);
      setDealsBtnTextColor(GlobalStyles.colors.white);
      setIsTicksView(false);
      setPostType(GlobalConstants.postCategories.deal.id);
    } else if (btnType === "CreateAd") {
      setTicksBtnTextColor(GlobalStyles.colors.white);
      setAddTickBtnTextColor(GlobalStyles.colors.white);
      setCreateAdBtnTextColor(GlobalStyles.colors.mustard);
      setAddDealBtnTextColor(GlobalStyles.colors.white);
      setDealsBtnTextColor(GlobalStyles.colors.white);
      setIsTicksView(false);
      setPostType(GlobalConstants.postCategories.ad.id);
    } else if (btnType === "Ticks") {
      setTicksBtnTextColor(GlobalStyles.colors.mustard);
      setAddTickBtnTextColor(GlobalStyles.colors.white);
      setCreateAdBtnTextColor(GlobalStyles.colors.white);
      setAddDealBtnTextColor(GlobalStyles.colors.white);
      setDealsBtnTextColor(GlobalStyles.colors.white);
      setIsTicksView(true);
    } else if (btnType === "Deals") {
      setTicksBtnTextColor(GlobalStyles.colors.white);
      setAddTickBtnTextColor(GlobalStyles.colors.white);
      setCreateAdBtnTextColor(GlobalStyles.colors.white);
      setAddDealBtnTextColor(GlobalStyles.colors.white);
      setDealsBtnTextColor(GlobalStyles.colors.mustard);
      setIsTicksView(true);
    } else {
      setTicksBtnTextColor(GlobalStyles.colors.white);
      setAddTickBtnTextColor(GlobalStyles.colors.mustard);
      setCreateAdBtnTextColor(GlobalStyles.colors.white);
      setAddDealBtnTextColor(GlobalStyles.colors.white);
      setDealsBtnTextColor(GlobalStyles.colors.white);
      setIsTicksView(false);
    }
  }

  const createPost = async () => {
    try {
      var obj = {
        text: postTextArea,
        details: postDetails,
        type: postType,
      };

      if (postImage) {
        obj.urls = [postImage];
      }
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}posts`,
        obj
      );
      if (resp.data.status) {
        setPostImage("");
        setPostTextArea("");
        setPostDetails("");
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.mustard}
                message={
                  GlobalConstants.postCategories[
                    postType == 1 ? "tick" : "deal"
                  ].title.toLowerCase() + " created successfuly!!!"
                }
              />
            );
          },
          placement: "top",
        });
      } else {
        // console.log(resp.data.status_msg," character count")
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={resp.data.status_msg}
              />
            );
          },
          placement: "top",
        });
      }
      setPostTextArea("");
      setPostDetails("");
    } catch (error) {
      changeColor("AddTick");
      console.log(error);
    }
  };
    
  const handleCountInputChange = (value) => {
    
    const plainTextLength = value.replace(/<[^>]*>/g, "").length;

    if (plainTextLength <= 300) {
      setPostTextArea(value);
      setRemainingCharacters(300 - plainTextLength);
    } else {
      const truncatedText = truncateToLimit(value, 300);
      setPostTextArea(truncatedText);
      setRemainingCharacters(0);
    }
  };
  const handleCountInputChangeForDetail = (value) => {
    
    const plainTextLength = value.replace(/<[^>]*>/g, "").length;

    if (plainTextLength <= 1500) {
      setPostDetails(value);
      setRemainingCharactersForDetail(1500 - plainTextLength);
    } else {
      const truncatedText = truncateToLimit(value, 1500);
      setPostDetails(truncatedText);
      setRemainingCharactersForDetail(0);
    }
  };

  const truncateToLimit = (htmlContent, limit) => {
    const plainText = htmlContent.replace(/<[^>]*>/g, ""); // Get plain text
    const truncatedText = plainText.substring(0, limit); // Truncate plain text
    const regex = new RegExp(`^.{0,${limit}}`); // Match the truncated length
    const matchedHtml = htmlContent.match(regex); // Ensure truncated HTML integrity
    return matchedHtml ? matchedHtml[0] : htmlContent; // Return truncated HTML
  };




  const getPostsByCategoryId = async (categoryId, page = 1) => {
    // If switching categories, reset the posts and page count
    if (selectedPostCategoryId !== categoryId) {
      setBusinessPosts([]);      
      setCurrentPage(1);         
      setHasMorePosts(true);     
      setSelectedPostCategoryId(categoryId); // Update category
    }
  
    try {
      setIsLoadingMore(true); 
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}v1/posts/category/${categoryId}?page=${page}`
      );
  
      if (resp.data.status) {
        const newPosts = resp.data.data;
  
        // Update posts state
        setBusinessPosts((prevPosts) => [...prevPosts, ...newPosts]);
        if (newPosts.length < 10) {
          setHasMorePosts(false);
        } else {
          setCurrentPage(page + 1); 
        }
      } else {
        toast.show({
          render: () => (
            <Toast
              color={GlobalStyles.colors.red}
              message={"Failed to get posts!"}
            />
          ),
          placement: "top",
        });
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsLoadingMore(false); 
    }
  };
  
  const loadMorePosts = () => {
    if (hasMorePosts) {
      getPostsByCategoryId(selectedPostCategoryId, currentPage);
    }
  };

  const Delete = async () => {
    try {
      const config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${GlobalConstants.townTicksBaseUrl}posts`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          post_id: selectedPost.id,
        }),
      };
  
      const resp = await axios.request(config);
  
      if (resp.data.status) {
        // Remove the deleted post from the local state
        setBusinessPosts((prevPosts) =>
          prevPosts.filter((post) => post.id !== selectedPost.id)
        );
  
        toast.show({
          render: () => (
            <Toast
              color={GlobalStyles.colors.mustard}
              message="Post deleted successfully!"
            />
          ),
          placement: "top",
        });
  
        // Fetch updated data from the backend for consistency
        getPostsByCategoryId(selectedPostCategoryId, 1);
      } else {
        toast.show({
          render: () => (
            <Toast color={GlobalStyles.colors.red} message={resp.data.status_msg} />
          ),
          placement: "top",
        });
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.show({
        render: () => (
          <Toast color={GlobalStyles.colors.red} message="Error deleting post!" />
        ),
        placement: "top",
      });
    }
  };

  const handleDeleteOk = async (isTrue) => {
    setDeletePopUp(false);
    if (isTrue) {
      await Delete();
    }
  };

  const onDeleteClick = async (data) => {
    // console.log(data);
    setDeletePopUp(true);
    setSelectedPost(data);
  };

  async function selectFile() {
    setIsLoading(true);
    let imageUrl = await uploadImage();
    if (imageUrl) {
      setPostImage(imageUrl);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please check image size should be less than 500KB!!!"}
            />
          );
        },
        placement: "top",
      });
    }
  }

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
    changeColor("AddTick");
  }, []);

  return (
    <>
      <ScrollView>
        <Center>
          <Box safeAreaTop bg="white" />

          <Box
            bg="#F5F5F5"
            px="1"
            py="3"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            maxW="900"
          >
            <VStack alignItems="center">
              <Stack
                alignItems="center"
                flexDirection={window?.innerWidth < 415 ? "column" : "row"}
              >
                <Stack
                  mt={10}
                  w={[230, 230, 230]}
                  justifyContent="center"
                  flexDirection={"row"}
                >
                  <Button
                    size="sm"
                    mx={1}
                    variant={"solid"}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={ticksBtnTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("Ticks");
                      getPostsByCategoryId(
                        GlobalConstants.postCategories.tick.id
                      );
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={ticksBtnTextColor}
                    >
                      Ticks
                    </Text>
                  </Button>

                  <Button
                    size="sm"
                    mx={1}
                    variant={"solid"}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={dealsBtnTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("Deals");
                      getPostsByCategoryId(
                        GlobalConstants.postCategories.deal.id
                      );
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={dealsBtnTextColor}
                    >
                      Deals
                    </Text>
                  </Button>
                  <Button
                    size="sm"
                    variant={"solid"}
                    mx={1}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={addTickBtnTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("AddTick");
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={addTickBtnTextColor}
                    >
                      Add Tick
                    </Text>
                  </Button>
                </Stack>
                <Stack
                  mt={window?.innerWidth < 415 ? 2 : 10}
                  w={[190, 190, 190]}
                  justifyContent="center"
                  flexDirection={"row"}
                >
                  <Button
                    size="sm"
                    variant={"solid"}
                    mx={1}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={addDealBtnTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("AddDeal");
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={addDealBtnTextColor}
                    >
                      Add Deal
                    </Text>
                  </Button>
                  <Button
                    // isDisabled={true}
                    size="sm"
                    variant={"solid"}
                    mx={1}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={createAdBtnTextColor}
                    borderWidth={2}
                    onPress={() => {
                      navigation.navigate("AdvertiserMainPage");
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={createAdBtnTextColor}
                    >
                      Create Ad
                    </Text>
                  </Button>
                </Stack>
              </Stack>

              {isTicksView ? (
                <>
                  <ScrollView
                    w={[260, 360, 610]}
                    showsVerticalScrollIndicator={false}
                  >
                    <VStack alignItems="center">
                      <Stack
                        justifyContent="center"
                        flexDirection={"row"}
                        space={3}
                        flexWrap="wrap"
                        m={2}
                      >
                        {businessPosts.map((x, index) => {
                          return (
                            <FeedCardNew
                              data={x}
                              showViewCount={false}
                              setSelectedPost={onDeleteClick}
                            />
                          );
                        })}
                      </Stack>
                    </VStack>
                    {businessPosts.length >= 10 && hasMorePosts && (
                    <Stack
                      w={[190, 190, 190]}
                      alignSelf="center"
                      
                    >
                      <Button
                         size="sm"
                         bg={GlobalStyles.colors.blue}
                         onPress={loadMorePosts}
                         isLoading={isLoadingMore} 
                         isLoadingText="Loading..."
                       >
                         <Text
                           fontWeight="bold"
                           fontFamily={GlobalStyles.text.fontFamily}
                           color={GlobalStyles.colors.white}
                         >
                           Load More
                         </Text>
                       </Button>
                    </Stack>
                   )}
                  </ScrollView>
                </>
              ) : (
                <>
                  <Box mt={10} alignItems="center" w={[250, 350, 400]}>
                    {/* Image Upload */}
                    <FormControl mt={2} w={[250, 350, 400]}>
                      <Pressable
                        onPress={() => {
                          selectFile();
                        }}
                      >
                        <Input
                          value={postImage ? postImage : "Upload Image"}
                          InputRightElement={
                            <Button
                              onPress={() => {
                                selectFile();
                              }}
                              bgColor="gray.200"
                            >
                              <Icon
                                size="6"
                                color="muted.500"
                                as={<MaterialIcons name="file-upload" />}
                              />
                            </Button>
                          }
                        />
                      </Pressable>
                    </FormControl>

                    
                    <FormControl mt={2} w={[250, 350, 400]}>

                      <Box
                          borderColor="gray.300"
                          borderWidth={1}
                          p={2}
                          minHeight={150}
                          maxHeight={150} 
                          overflow="auto" 
                          bgColor="white"
                        >
                          <ReactQuill
                            theme="snow"
                            value={postTextArea}
                            onChange={(value) => handleCountInputChange(value)}  // for web
                            onChangeText={(text) => handleCountInputChange(text)} // for android and ios
                            modules={{
                              toolbar: [
                                
                                ["link"],
                              ],
                            }}
                            placeholder="Summary"
                            // maxLength={300}
                          />
                        </Box>

                      
                    </FormControl>
                    <Text alignSelf={"flex-end"} fontSize="xs" color={remainingCharacters === 0 ? "red.500" : "gray.500"}> 
                    {remainingCharacters} characters remaining
                    </Text>

                    
                    <FormControl mt={2} w={[250, 350, 400]}>
                      <Box
                        borderColor="gray.300"
                        borderWidth={1}
                        p={2}
                        minHeight={150}
                        maxHeight={150} 
                        overflow="auto" 
                        bgColor="white"
                      >
                        <ReactQuill
                          theme="snow"
                          value={postDetails}
                          onChange={(value) => handleCountInputChangeForDetail(value)} // for web
                          onChangeText={(text) => handleCountInputChangeForDetail(text)} // for android and ios
                          modules={{
                            toolbar: [
                              
                              ["link"],
                            ],
                          }}
                          placeholder="Write the details here..."
                          // maxLength={1500}
                        />
                      </Box>
                    </FormControl>
                    <Text alignSelf={"flex-end"} fontSize="xs" color={remainingCharactersForDetail === 0 ? "red.500" : "gray.500"}> 
                    {remainingCharactersForDetail} characters remaining
                    </Text>
                    <Button
                      size="sm"
                      variant={"solid"}
                      mx={1}
                      px="3"
                      bg={GlobalStyles.colors.blue}
                      borderColor={GlobalStyles.colors.mustard}
                      borderWidth={2}
                      onPress={() => {
                        createPost();
                      }}
                    >
                      <Text
                        fontWeight="bold"
                        fontFamily={GlobalStyles.text.fontFamily}
                        color={GlobalStyles.colors.white}
                      >
                        Submit
                      </Text>
                    </Button>
                  </Box>
                </>
              )}
            </VStack>
            {deletePopUp ? (
              <ConfigureSettingsPopup
                heading="Confirm Deletion"
                body="Are you sure you want to delete"
                data={deletePopUp}
                onChange={handleDeleteOk}
              />
            ) : (
              <></>
            )}
          </Box>
        </Center>
      </ScrollView>
    </>
  );
};

export default BusinessMainPage;

