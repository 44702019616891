import React, { useState, useEffect, useCallback } from "react";
import {
  Center,
  Stack,
  HStack,
  AspectRatio,
  Heading,
  Text,
  Box,
  Pressable,
  Modal,
  Button,
  Avatar,
  VStack,
  Image,
  useToast,
} from "native-base";
import { GlobalStyles } from "../constants/styles";
import { Linking } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Image as NativeImage } from "react-native";
import ConfigureSettingsPopup from "./ConfigureSettingsPopup";
import ClipboardPopup from "./ClipboardPopup";
import { Ionicons } from "@expo/vector-icons";
import Toast from "../components/Toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { GlobalConstants } from "../constants/constants";
import RenderHtml from 'react-native-render-html';  // Import RenderHtml
// import { getDisplayDate } from "../utils/general";

const FeedCardForCitizen = ({ data, setSelectedPost, friends }) => {
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };
  const toast = useToast();
  const [imageUrl, setImageUrl] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [detailPopUp, setDetailPopUp] = useState(false);
  // const [saveButtonText, setSaveButtonText] = useState(data.is_saved ? "UnSave" : "Save");

  const [isClipboardPopupOpen, setIsClipboardPopupOpen] = useState(false);

  const openClipboardPopup = () => {
    setIsClipboardPopupOpen(true);
  };

  const closeClipboardPopup = () => {
    setIsClipboardPopupOpen(false);
  };

  const shareClipboardPopup = async (selectedFriendIds) => {
    console.log(selectedFriendIds);
    await shareFeedWithFriends(selectedFriendIds);
    setIsClipboardPopupOpen(false);
  };

  const shareFeedWithFriends = async (selectedFriendIds) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}friend/post/share`,
        {
          post_id: data.id,
          friend_ids: selectedFriendIds,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Successfully shared with friends!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to share post!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = (obj) => {
    setModalVisible(true);
    setImageUrl(data.post_urls[0]);
    // updateAdViews(obj);
  };

  const closeModal = () => {
    setImageUrl("");
    setModalVisible(false);
  };

  function getAvatarChar(fullName) {
    let nameShortCode = "";
    if (fullName && fullName.split(" ").length > 1) {
      nameShortCode =
        fullName.split(" ")[0].charAt(0).toUpperCase() +
        fullName.split(" ")[1].charAt(0).toUpperCase();
    } else {
      nameShortCode = fullName.charAt(0).toUpperCase();
    }
    return nameShortCode;
  }

  const handleChange = useCallback(async (value) => {
    setDetailPopUp(false);
  }, []);

  const isHtmlContent = (content) => {
    const regex = /<[^>]*>/g;  // Check for HTML tags
    return regex.test(content);
  }; 
  
  const processTickText = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Regex to extract URL
    const parts = text.split(urlRegex); // Split text into URL and non-URL parts
  
    return parts.map((part, index) =>
      urlRegex.test(part) ? (
        <Text
          key={index}
          style={{ color: "blue", textDecorationLine: "underline" }}
          onPress={() => Linking.openURL(part)}
        >
          {part}
        </Text>
      ) : (
        <Text key={index}>{part}</Text>
      )
    );
  };
  const processTickContent = (content) => {
    if (isHtmlContent(content)) {
      // If it's HTML content, process it accordingly
      return (
        <RenderHtml
          source={{ html: content }}
          baseStyle={{
            fontSize: 18,
            fontFamily: GlobalStyles.text.fontFamily,
            color: '#000',
            marginTop: 4,
          }}
          tagsStyles={{
            a: {
              color: 'blue',
              textDecorationLine: 'underline',
            },
          }}
          
        />
      );
    } else {
      // If it's plain text, process it using processTickText
      return (
        <Text
          fontWeight="400"
          fontSize="18"
          mt={1}
          fontFamily={GlobalStyles.text.fontFamily}
        >
          {processTickText(content)}
        </Text>
      );
    }
  };

  function getDisplayDate(year, month, day) {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    let compDate = new Date(year, month - 1, day); // month - 1 because January == 0
    let diff = today.getTime() - compDate.getTime(); // get the difference between today(at 00:00:00) and the date
    let stringDate = compDate.toDateString().split(" ");
    if (compDate.getTime() > today.getTime()) {
      return stringDate[1] + " " + stringDate[2];
    } else if (compDate.getTime() == today.getTime()) {
      return "Today";
    } else if (diff <= 24 * 60 * 60 * 1000) {
      return "Yesterday";
    } else {
      if (year != today.getFullYear()) {
        return stringDate[1] + " " + stringDate[2] + " " + stringDate[3];
      } else {
        return stringDate[1] + " " + stringDate[2];
      }
    }
  }

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
  }, []);

  return (
    <>
      <Modal
        size="xl"
        isOpen={modalVisible}
        onClose={closeModal}
        animationType="slide"
        transparent
      >
        <Modal.Content
          backgroundColor={"#43464b"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Modal.CloseButton />
          {imageUrl && (
            <Image
              w={[500, 700, 900]}
              h={[500, 700, 900]}
              source={{ uri: imageUrl }}
              resizeMode="contain"
              // style={{ width: "150%", height: "150%", resizeMode: "contain" }}
            />
          )}
        </Modal.Content>
      </Modal>
      <Center
        m={2}
        _text={{
          color: "warmGray.50",
          fontWeight: "medium",
        }}
      >
        <Box alignItems="center">
          <Box
            rounded="lg"
            // overflow="hidden"
            borderColor={GlobalStyles.colors.mustard}
            borderWidth="1"
            // _dark={{
            //   borderColor: "coolGray.600",
            //   backgroundColor: "gray.700",
            // }}
            // _web={{
            //   shadow: 2,
            //   borderWidth: 0,
            //   borderColor: GlobalStyles.colors.mustard
            // }}
            // _light={{
            //   backgroundColor: "gray.50",
            //   borderColor: GlobalStyles.colors.mustard
            // }}
          >
            <Stack p="4" w={[250, 250, 600]}>
            <Stack alignSelf="flex-start" mb={2}>
                <Text fontSize="xs">
                {data?.shared_user_name ? data.shared_user_name : ""}
                </Text>
              </Stack>
              <Stack alignSelf="flex-end">
                <Text fontSize="xs">
                  {data?.created_at
                    ? getDisplayDate(
                        data.created_at.split("-")[0],
                        data.created_at.split("-")[1],
                        data.created_at.split("-")[2]
                      )
                    : "few days ago"}
                </Text>
              </Stack>
              <Stack space={2}>
                <HStack ml="-2" mt={-5}>
                  <Avatar
                    size="sm"
                    source={{
                      uri: data.logo_url ? data.logo_url : "",
                    }}
                    bg={GlobalStyles.colors.blue}
                  >
                    {data?.name
                      ? getAvatarChar(data.name)
                      : getAvatarChar("Anonymous")}
                  </Avatar>
                  <Heading
                    mt={2}
                    size="sm"
                    fontFamily={GlobalStyles.text.fontFamily}
                  >
                    {" "}
                    <Text fontSize={18}>
                      {data?.name ? data.name : "Anonymous"}
                    </Text>
                  </Heading>
                </HStack>
              </Stack>
              <Text
                fontWeight="400"
                fontSize="18"
                mt={1}
                fontFamily={GlobalStyles.text.fontFamily}
                // noOfLines={5}
              >
                {processTickContent(data.tick)}
                {/* Bengaluru (also called Bangalore) is the center of India's
                high-tech industry. The city is also known for its parks and
                nightlife. */}
              </Text>
            </Stack>
            {data && data.post_urls && data.post_urls[0] && (
              <Box>
                <Pressable onPress={() => openModal(data)}>
                  <AspectRatio ratio={3 / 2}>
                    <NativeImage
                      // style={{
                      //   width: "100%",
                      //   height: undefined,
                      //   aspectRatio: 2 / 1,
                      // }}
                      resizeMode={"cover"}
                      source={{
                        uri: data.post_urls ? data.post_urls[0] : "",
                      }}
                      alt="image"
                    />
                  </AspectRatio>
                </Pressable>
              </Box>
            )}
            {user.user_category ==
              GlobalConstants.userCategories.citizen.id ? (
              <HStack p="1" w={[250, 250, 600]}>
                <Stack w={[window?.innerWidth > 600 && 200]}>
                  <Button
                    leftIcon={
                      <Image
                        source={require("../../assets/save.png")}
                        alt="Save"
                        size="7"
                      />
                      // <MaterialIcons name="save" size={24} color="black" />
                    }
                    isDisabled={data.savedAllowed ? false : true}
                    size="sm"
                    variant="unstyled"
                    onPress={() => {
                      setSelectedPost(data);
                      data.is_saved = !data.is_saved;
                    }}
                  >
                    {data.is_saved ? "Unsave" : "Save"}
                  </Button>
                </Stack>
                <Stack w={[window?.innerWidth > 600 && 200]}>
                  <Button
                    isDisabled={!data.detail}
                    leftIcon={
                      <Image
                        source={require("../../assets/detail.png")}
                        alt="Save"
                        size="7"
                      />
                      // <MaterialIcons name="list-alt" size={24} color="black" />
                    }
                    size="sm"
                    variant="unstyled"
                    onPress={() => {
                      
                      setDetailPopUp(true);
                    }}
                  >
                    {"Detail"}
                  </Button>
                </Stack>
                <Stack w={[window?.innerWidth > 600 && 200]}>
                  <Button
                    leftIcon={
                      <Image
                        source={require("../../assets/share.png")}
                        alt="Save"
                        size="7"
                      />
                    }
                    size="sm"
                    variant="unstyled"
                    onPress={openClipboardPopup}
                  >
                    {"Share"}
                  </Button>
                </Stack>
              </HStack>
            ) :  <HStack p="1" w={[250, 250, 600]}>
            <Stack w={[window?.innerWidth > 600 ? 600 : 220]}>
              <Button
                leftIcon={
                  <Image
                    source={require("../../assets/share.png")}
                    alt="Save"
                    size="7"
                  />
                }
                size="sm"
                variant="unstyled"
                onPress={openClipboardPopup}
              >
                {"Share"}
              </Button>
            </Stack>
          </HStack> }
          </Box>
        </Box>
      </Center>

      <ClipboardPopup
        isOpen={isClipboardPopupOpen}
        onClose={closeClipboardPopup}
        onShare={shareClipboardPopup}
        url={window.location.host + "/invite/feed/" + data.id}
        friends={friends}
      />

      {detailPopUp ? (
        <ConfigureSettingsPopup
          heading="Detail"
          body={processTickContent(data.detail || "No details available")}
          data={detailPopUp}
          onChange={handleChange}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default FeedCardForCitizen;
